import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2022/gastro/1.jpeg';
import blogd2 from '../../assets/images/newsPub.png';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                World Gastroenterology Organization Lagos training centre workshop held at LUTH
                </h2>
                <p className='blog-one__text'>
                  The World Gastroenterology Organisation (WGO) Lagos Training
                  Centre in Collaboration with the Lagos University Teaching
                  Hospital (LUTH) held a training workshop on Advanced
                  Gastrointestinal Endoscopy with the theme: Quality in
                  Endoscopy. This was a 6-day workshop which commenced on
                  Sunday, 12th June 2022 facilitated by a team of
                  Gastroenterologists and experts from the USA, South Africa and
                  Nigeria, and includes participants from within and outside the
                  country. These participants were made up of
                  gastroenterologists, general surgeons, paediatricians,
                  anaesthesiologists, radiologists, nurses, industry sponsors
                  and other hospital support staff. During the program, several
                  live procedures were performed including colonic polyp
                  resections, variceal ligation/banding, oesophageal/colonic and
                  rectal stenting and Endoscopic Retrograde
                  Cholangiopancreatography (ERCP). These procedures were
                  preceded by detailed and interactive lectures (virtual and
                  in-person) on important aspects of upper and lower endoscopic
                  procedures, from the 12th – 14th June 2022
                  <br />                  
                </p>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'>Department of Medicine</a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='World Gastroenterology Organization Lagos training centre workshop | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
